import "./style.css";
import classNames from "classnames";
import Modal from "../modal";

interface ModalPrivacyPolicyProps {
  className?: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
}

export default function ModalPrivacyPolicy({
  className = "w-10/12",
  isOpen,
  setIsOpen,
}: ModalPrivacyPolicyProps) {
  return (
    <Modal
      className={classNames(
        "modal-privacy-policy-container",
      )}
      modalBoxClass={
        "w-11/12 max-w-5xl"
      }
      title="Privacy Policy"
      titlePosition="center"
      // titleBorder={false}
      isOpen={isOpen}
      setIsOpen={() => setIsOpen && setIsOpen(false)}
    >
      <div className="privacy-policy-detail text-left">
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>

        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
        <p className="indent-5 my-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique accusantium blanditiis consectetur, qui odit deserunt velit officiis numquam nisi dolorum sunt ullam adipisci veniam voluptatibus illo. Nostrum vitae odio nisi.</p>
      </div>
    </Modal>
  );
}