import classNames from "classnames";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Select from "react-select";

interface SelectBoxProps {
  options?: any[];
  value?: any;
  placeholder?: string;
  menuIsOpen?: boolean;
  selectedTextAlign?: string;
  onChange?: (value: any) => void;
  className?: string;
  disabled?: boolean;
  error?: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '50px',
    minHeight: '50px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: isMobile ? '0.8rem' : '1rem'
  })
};

export default function SelectBox({
  options,
  value,
  placeholder,
  menuIsOpen,
  selectedTextAlign = "left",
  onChange,
  className,
  disabled,
  error,
  ...rest
}: SelectBoxProps) {

  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleSelectedChange = (option: any) => {
    setSelectedOption(option);
    onChange && onChange(option);
  };

  return (
    <div className={className}>
      <Select
        className={classNames(
          `text-${selectedTextAlign}`,
          error && 'border-red-500 rounded-md border-[1px]',
        )}
        styles={customStyles}
        placeholder={placeholder}
        menuIsOpen={menuIsOpen}
        options={options}
        value={selectedOption}
        onChange={handleSelectedChange}
        isDisabled={disabled}
        {...rest}
      />
      {
        error && (
          <div className="text-red-600 text-sm w-full cursor-default mt-1">
            { error }
          </div>
        )
      }
    </div>
  );
}