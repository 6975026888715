import classNames from "classnames";
import { Tour, TourHighlight } from "../../../../../interface/model/tour";
import { useEffect, useState } from "react";
import { scrollToTop } from "../../../../../helper";

interface TourHighlightProps {
  className?: "",
  data: Tour,
}

export default function TourHighlights({
  className,
  data,
}: TourHighlightProps ) {
  const [highlights, setHighlights] = useState<TourHighlight[]>();

  useEffect(() => {
    setHighlights(data?.highlights);
  }, [data]);

  return (
    <div
      className={classNames(
        "mt-10",
        className
      )}
    >
      <div className="w-10/12 m-auto text-left">
        {/* Title */}
        <div className="title-container">
          <h1 className="font-bold text-gray-600 text-2xl lg:text-4xl">
            {data?.title}
          </h1>
        </div>

        {/* Detail */}
        <div className="detail-container my-4">
          <span className="font-semibold text-gray-600 text-md lg:text-xl">
            {data?.detail}
          </span>
        </div>

        {/* Highlight */}
        <div className="highlight-container mt-10">
          {highlights?.map((item: any, index: number) => {
            return (
              <div key={`${data?.title}-highlight-${index}`}>
                {/* Desktop */}
                <div className="display-desktop hidden lg:block">
                  <div className="mb-10">
                    {index % 2 === 0 ? (
                      <div
                        className="grid grid-cols-2 gap-4"
                      >
                        {/* image */}
                        <img
                          src={item?.image}
                          alt={`highlight-${item?.title}-${index}`}
                        />

                        {/* detail */}
                        <div className="pl-5">
                          <h3 className="text-3xl text-gray-600 pt-10 pb-8 font-semibold">{item?.title}</h3>
                          <p className="text-lg text-gray-800 pr-5">{item?.detail}</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="grid grid-cols-2 gap-4"
                      >
                        {/* detail */}
                        <div className="pl-5">
                          <h3 className="text-3xl text-gray-600 pt-10 pb-8 font-semibold">{item?.title}</h3>
                          <p className="text-lg text-gray-800 pr-5">{item?.detail}</p>
                        </div>

                        {/* image */}
                        <img
                          src={item?.image}
                          alt={`highlight-${item?.title}-${index}`}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Mobile */}
                <div className="display-mobile lg:hidden mb-5">
                  {/* <div
                    className="grid grid-cols-2 gap-4"
                  > */}
                    {/* image */}
                    <img
                      src={item?.image}
                      alt={`highlight-${item?.title}-${index}`}
                    />

                    {/* detail */}
                    <div className="">
                      <h3 className="text-xl text-gray-600 py-5 font-semibold">{item?.title}</h3>
                      <p className="text-gray-800 pr-5">{item?.detail}</p>
                    </div>
                  {/* </div> */}
                </div>
              </div>
            );
          })}
        </div>

        {/* Back to top */}
        <div className="submit text-center mt-16 lg:mt-0 lg:mb-16">
          <button
            type="button"
            className={classNames(
              "btn rounded-3xl min-w-40",
              "nst-btn",
              // "bg-blue-800 text-white ",
              // "hover:text-blue-800 hover:bg-white hover:border-blue-800",
            )}
            onClick={() => scrollToTop()}
          >
            Back on Top
          </button>
        </div>

      </div>
    </div>
  )
}