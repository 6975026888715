/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLoadingContext } from "../../context/loading";
import { RESPONSE_STATUS, ROUTE } from "../../config";
import "./style.css";
import classNames from "classnames";
import BreadCrumb, { BreadCrumbItem } from "../../components/breadcrumb";
import useGo from "../../helper/use-go";
import BannerMini from "../../components/banner-mini";
import { serviceService } from "../../api/service";
import CoverPage from "../../components/cover-page";
import ServiceDetailModal from "./components/service-detail-modal";

export default function Services() {
  const { setLoading } = useLoadingContext();
  const go = useGo();

  const [services, setServices] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  useEffect(() => {
    handleSetServices();
  }, []);

  const handleSetServices = async () => {
    const getServices = async () => {
      setLoading(true);

      const service = serviceService();
      const res = await service.getServices({});
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        res.data.map((item: any) => {
          item.link = `${ROUTE.SERVICE}/${item.id}`;
          return item;
        });

        setServices(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getServices();
  }

  const openModalService = (id: number) => {
    if (id) {
      setSelectedId(id);
      setIsOpenModal(true);
    }
  }

  return (
    <>
      <div className={classNames(
        "services-container",
      )}>
        <BreadCrumb
          className={classNames(
            "bg-white text-gray-800 mt-[70px] z-10",
            "lg:mt-[74.5px] w-full absolute",
          )}
        >
          <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
          <BreadCrumbItem title="Services" isActive />
        </BreadCrumb>

        {/* Header */}
        <div className="header-container">
          <CoverPage
            title={"Our Services"}
            detail={"Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage."}
            detailPosition="left"
            isHideSearch
            className="pt-12"
          />
        </div>
      </div>

      {/* Content */}
      <div className="bg-white">
        <div className="center shadow-xl w-11/12 bg-white m-auto left-0 right-0 pt-20 pb-8 relative bottom-14 lg:bottom-32">
          {/* Title */}
          <h1 className={classNames(
            "font-semibold text-2xl text-gray-700 mb-2",
            "lg:text-4xl",
          )}>
            PERSONALISE YOUR TOUR
          </h1>

          {/* Detail */}
          <div className={classNames(
            "detail mt-5 mb-5 px-3 text-sm",
            "lg:mb-10 lg:px-20 lg:text-lg"
          )}>
            <p>Because no two Insign Vacations guests are the same.</p>
            <p>When you travel with Insight Vacations, our itineraries are just the starting point. We make it easy to personalise your vacation to suit you. Whether it's the size of the group you travel with, the style of tour, or the optional experiences you add to reflect your own passions, an Insight Vacations tour is whatever you choose to make it.</p>
          </div>

          {/* Banner */}
          {
            services && services.map((item: any, index: number) => {
              return (
                <div className="pt-8 px-2 lg:px-8" key={`services-${index}`}>
                  <BannerMini
                    title={item.title}
                    image={item.image}
                    // onClick={() => go(item.link)}
                    onClick={() => item.id && openModalService(item.id)}
                  />
                </div>
              )
            })
          }
        </div>
      </div>

      {/* Modal service */}
      <ServiceDetailModal
        isOpen={isOpenModal}
        setIsOpen={() => {
          setIsOpenModal(false);
          setSelectedId(0);
        }}
        // onClose={() => setIsOpenModal(false)}
        id={selectedId}
      />
    </>
  )
}