import axios from "axios";
import { RESPONSE_STATUS } from "../config";

const handleResponse = (res: any, payload?: any) => {
  // console.log(
  //   `%c${res.config.method} => ${res.config.url}`,
  //   "color: SpringGreen",
  //   payload,
  //   res.data,
  // );
  return res.data;
};

const handleError = (error: any) => {
  // console.error("Handle error:", error);
  if (error.response?.status === 401) {
    // logout(error);
  } else {
    return {
      status: RESPONSE_STATUS.ERROR,
      error: error.response?.data,
    };
  }
};

export default function apiService() {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

  return {
    get: async (path: string, param?: object) => {
      const config: object = { params: param };
      return axios
        .get(`${apiUrl}${path}`, config)
        .then((res) => {
          return handleResponse(res, param);
        })
        .catch(handleError);
    },

    post: async (path: string, data: object) => {
      return axios
        .post(`${apiUrl}${path}`, data)
        .then((res) => {
          return handleResponse(res, data);
        })
        .catch(handleError);
    },

    put: async (path: string, data: object) => {
      return axios
        .put(`${apiUrl}${path}`, data)
        .then((res) => {
          return handleResponse(res, data);
        })
        .catch(handleError);
    },

    delete: async (path: string) => {
      return axios
        .delete(`${apiUrl}${path}`)
        .then((res) => {
          return handleResponse(res);
        })
        .catch(handleError);
    },
  };
}