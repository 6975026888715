import classNames from "classnames";
import "./style.css";

interface RatingProps {
  rating: number;
  name: string;
  className?: string;
}

export default function Rating({
  rating,
  name,
  className,
}: RatingProps) {

  return (
    <div className={classNames(
      "rating-component rating rating-lg rating-half",
      className,
    )}>
      <input type="radio" name={name} className="rating-hidden" defaultChecked={rating === 0 && rating > 0.5} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-1 bg-yellow-500 cursor-default" defaultChecked={rating <= 0.5 && rating > 0} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-2 bg-yellow-500 cursor-default" defaultChecked={rating <= 1 && rating > 0.5} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-1 bg-yellow-500 cursor-default" defaultChecked={rating <= 1.5 && rating > 1} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-2 bg-yellow-500 cursor-default" defaultChecked={rating <= 2 && rating > 1.5} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-1 bg-yellow-500 cursor-default" defaultChecked={rating <= 2.5 && rating > 2} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-2 bg-yellow-500 cursor-default" defaultChecked={rating <= 3 && rating > 2.5} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-1 bg-yellow-500 cursor-default" defaultChecked={rating <= 3.5 && rating > 3} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-2 bg-yellow-500 cursor-default" defaultChecked={rating <= 4 && rating > 3.5} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-1 bg-yellow-500 cursor-default" defaultChecked={rating <= 4.5 && rating > 4} disabled />
      <input type="radio" name={name} className="mask mask-star-2 mask-half-2 bg-yellow-500 cursor-default" defaultChecked={rating <= 4.5 && rating > 4.5} disabled />
    </div>
  )
}