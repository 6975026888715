/* eslint-disable no-useless-escape */
import { VALIDATE } from "../config";

export const isRequired = (
  value: string | number | boolean | Date,
): boolean => {
  const text: string = value !== undefined ? `${value}`.trim() : "";
  return /^\S+.*$/.test(text);
};

export const isEmail = (value: string): boolean => {
  return /^[\w\.-]+@[\w\.-]+\.\w+$/.test(value);
};

export const validateRequired = (
  value: string | number | undefined,
  setTextValid: (valid: string) => void,
): boolean => {
  let isValid = true;
  if (isRequired(value || "")) {
    setTextValid("");
  } else {
    setTextValid(VALIDATE.REQUIRED);
    isValid = false;
  }
  return isValid;
};

export const validateEmail = (
  value: string,
  setTextValid: (value: string) => void,
): boolean => {
  let isValid = true;
  if (isRequired(value)) {
    if (isEmail(value)) {
      setTextValid("");
    } else {
      setTextValid(VALIDATE.EMAIL);
      isValid = false;
    }
  } else {
    setTextValid(VALIDATE.REQUIRED);
    isValid = false;
  }
  return isValid;
};
