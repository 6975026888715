/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Modal from "../../../components/modal";
import { useLoadingContext } from "../../../context/loading";
import { serviceService } from "../../../api/service";
import { RESPONSE_STATUS } from "../../../config";
import classNames from "classnames";
import { scrollToElement } from "../../../helper/scroll";

interface ServiceDetailModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
  id?: number;
}

export default function ServiceDetailModal({
  className,
  isOpen,
  setIsOpen,
  id,
}: ServiceDetailModalProps) {
  const { setLoading } = useLoadingContext();

  const [detail, setDetail] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (id) {
      handleSetServiceDetail();
      scrollToElement(`service-detail-${id}`, "auto");
    }
  }, [id]);

  const handleSetServiceDetail = async () => {
    const getById = async () => {
      setLoading(true);

      const service = serviceService();
      const res = await service.getServiceById(id);
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setDetail(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getById();
  };

  const close = () => {
    setIsOpen && setIsOpen(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={close}
      btnCloseClass="nst-btn rounded-3xl min-w-[80px] mb-10"
      btnClose="Back"
      onClose={close}
      title={""}
      modalBoxClass="w-11/12 max-w-5xl p-0"
    >
      <div
        id={`service-detail-${id}`}
        className={classNames(
          "bg-white",
          className
        )}
      >
        {/* Cover */}
        <section
          className={classNames(
            "mb-10 min-h-80 align-middle",
            "nst-bg"
          )}
          style={
            detail?.image && {
              // backgroundImage: `url(${detail?.image})`
              backgroundColor: '#018181'
            }
          }
        >
          <div className={classNames(
            "text-white absolute text-5xl text-center h-full align-middle top-32 w-full",
            "lg:text-6xl lg:pr-10 lg:right-0 lg:text-right"
          )}>
            {detail?.title}
          </div>
        </section>

        {/* Description */}
        <section className="mt-5 mb-10 w-11/12 m-auto">
          <p className="w-full lg:text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam voluptatum similique amet, velit obcaecati accusantium dignissimos, possimus explicabo, atque reprehenderit corrupti accusamus et in labore necessitatibus qui quidem excepturi asperiores?</p>
        </section>

        {/* Detail */}
        <section className="mt-5 mb-10 w-11/12 m-auto">
          <div className="lg:grid lg:grid-cols-2 lg:gap-4">
            {
              detail?.details?.map((item: any, index: number) => (
                <div key={`${detail?.title}-detail-${index}`} className="mb-5 lg:mb-0">
                  <img src={item?.image} alt={`service-${detail?.title}-${index}`} />
                  <p className="mt-5 text-justify indent-5">{item?.description}</p>
                </div>
              ))
            }
          </div>
        </section>

      </div>
    </Modal>
  )
}