import { useEffect, useState } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";

export default function useDevices() {
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile);
  const [isTabletDevice, setIsTabletDevice] = useState(isTablet);
  const [isDesktopDevice, setIsDesktopDevice] = useState(isDesktop);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 768);
      setIsTabletDevice(window.innerWidth > 768 && window.innerWidth <= 1024);
      setIsDesktopDevice(window.innerWidth > 1024);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice
  };
}
