/* eslint-disable @typescript-eslint/no-redeclare */
import classNames from "classnames";

interface BreadCrumbItem {
  title: string;
  onClick?: () => void;
  isActive?: boolean;
  className?: string;
}

export function BreadCrumbItem({
  title,
  onClick,
  isActive = false,
  className,
}: BreadCrumbItem) {
  return (
    <li className={className} onClick={onClick}>
      <span className={isActive ? "font-semibold" : ""}>{title}</span>
    </li>
  )
}

interface BreadCrumbProps {
  children: React.ReactNode;
  className?: string;
}

export default function BreadCrumb({
  children,
  className,
}: BreadCrumbProps) {

  return (
    <>
      <div
        className={classNames(
          "breadcrumbs text-sm cursor-pointer pl-3 lg:pl-10 shadow-sm",
          className
        )}
        key="breadcrumb"
      >
        <ul>
          {children}
        </ul>
      </div>
    </>
  )
}
