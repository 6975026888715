import classNames from "classnames"

interface HomeExploreProps {
  data: string[];
  isAnimate?: boolean;
}

export default function HomeExplore ({
  data,
  isAnimate,
}: HomeExploreProps) {
  return (
    <>
      <div
        className={classNames("explore-container", "mt-10 mb-20")}
        data-aos={isAnimate ? "fade-up" : "fade"}
      >
        {/* Title */}
        <h1 className={classNames("title", "font-semibold text-2xl text-gray-600 w-4/5 m-auto")}>EXPLORE OUT DESTINATIONS</h1>

        <div className={classNames("explore-wrapper", "lg:grid lg:grid-cols-4 lg:gap-5 lg:w-4/5 m-auto mt-10")}>
          {
            data && data?.map((item: any, index: number) => 
              <div
                key={"explore-" + index}
                className={classNames("explore", "rounded-md bg-gray-400 min-h-52 m-2 lg:m-0")}
                style={{
                  // backgroundImage: "url(/asset/images/mock/home-explore.png)",
                  backgroundSize: "cover",
                  backgroundColor: "#018181",
                }}
              >
                <span className={classNames("detail", "flex w-auto h-full items-end pl-5 pb-5 text-white text-lg")}>
                  {item}
                </span>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}