/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { useEffect, useState } from "react";
import { useLoadingContext } from "../../context/loading";
import { DELAY, ROUTE } from "../../config";
import HomeRecommended from "./components/recommended";
import HomeExplore from "./components/explore";
import HomeWhyChooseUs from "./components/why-choose-us";
import CoverPage from "../../components/cover-page";
import Aos from "aos";
import "aos/dist/aos.css";
import ModalSearchTour from "../../components/modal-search-tour";

export default function Home() {
  const { setLoading } = useLoadingContext();
  const [search, setSearch] = useState<string>("");
  const [isSearch, setIsSearch] = useState<boolean>(false);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, DELAY);
  }, []);

  const handleSearch = () => {
    search && !isSearch && setIsSearch(true);
  }

  // Mock city
  const cities: string[] = [
    "Bangkok",
    "Krabi",
    "Chiang Mai",
    "Kanjanaburi",
    "Vietnam",
    "Cambodia",
    "Myanmar",
    "Singapore"
  ];

  return (
    <>
      <div className="home-container">
        {/* Header */}
        <CoverPage
          // image="/asset/images/mock/cover-home.png"
          title={"Exploring \nThailand"}
          detail={"Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage."}
          detailPosition={"right"}
          viewLink={ROUTE.DESTINATION}
          isAnimate
          inputSearch={(value: string) => setSearch(value)}
          onSearch={handleSearch}
        />

        {/* Recommended Tour */}
        <HomeRecommended isAnimate />

        {/* Explore out destinations */}
        <HomeExplore data={cities} isAnimate />

        {/* Why choose us */}
        <HomeWhyChooseUs isAnimate />

      </div>

      {/* Modal search */}
      <ModalSearchTour
        isOpen={isSearch}
        setIsOpen={() => setIsSearch(false)}
        search={search}
      />
    </>
  );
}
