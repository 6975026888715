export interface ContactForm {
  travel_date: string;
  firstname: string;
  lastname: string;
  phone_number: string;
  email: string;
  accept_policy: boolean;
  message?: string;
}

export const mockContactForm: ContactForm = {
  travel_date: "",
  firstname: "",
  lastname: "",
  phone_number: "",
  email: "",
  accept_policy: false,
  message: ""
}

export interface ContactFormValid {
  travel_date_valid: string;
  firstname_valid: string;
  lastname_valid: string;
  phone_number_valid: string;
  email_valid: string;
  accept_policy_valid: string;
  message_valid?: string;
}

export const mockContactFormValid: ContactFormValid = {
  travel_date_valid: "",
  firstname_valid: "",
  lastname_valid: "",
  phone_number_valid: "",
  email_valid: "",
  accept_policy_valid: "",
  message_valid: ""
}
