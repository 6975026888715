import classNames from "classnames"

interface HomeWhyChooseUsProps {
  className?: string;
  isAnimate?: boolean;
}

export default function HomeWhyChooseUs({
  className,
  isAnimate,
}: HomeWhyChooseUsProps) {
  return (
    <>
      <div
        className={classNames(
          "why-choose-us-container",
          "h-96 mb-48",
          className
        )}
        data-aos={isAnimate ? "fade-up" : "fade"}
      >
        <div className="why-choose-bg-1 bg-[#018181] h-2/4"></div>
        <div className="why-choose-bg-2 bg-white h-2/4"></div>
        <div
          className={classNames(
            "why-choose-wrapper",
            "w-full m-auto px-2 lg:px-0"
          )}
          style={{
            position: "relative",
            transform: "translateY(-80%)",
          }}
        >
          <div className={classNames("title", "font-semibold text-2xl text-white w-4/5 m-auto mt-14 mb-5 lg:my-10")}>WHY CHOOSE US</div>

          {/* Desktop */}
          <div className={classNames(
            "content",
            "hidden lg:grid lg:grid-cols-3 lg:gap-5",
            "w-full lg:w-4/5 m-auto",
          )}>
            {[1, 2, 3]?.map((item: any, index: number) => (
              <img
                src="/asset/images/mock/card.png"
                alt={"choose" + index}
                key={"why-choose-img-" + index}
              />
            ))}
          </div>

          {/* Mobile */}
          <div className={classNames(
            "content",
            "lg:hidden",
            "w-full lg:w-4/5 m-auto",
          )}>
            <div className="carousel w-full space-x-2">
              {[1, 2, 3]?.map((item: any, index: number) => (
                <div
                  id="slide1"
                  className="carousel-item relative w-full"
                  key={"why-choose-img-" + index}
                >
                  <img
                    alt="1"
                    src="/asset/images/mock/card.png"
                    className="w-full"
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={classNames(
            "detail",
            "w-full lg:w-4/5 m-auto bg-white py-10 px-5 shadow-md"
          )}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex, ea
            perferendis! Quae eaque sed eveniet doloribus illum obcaecati nulla,
            voluptatibus ipsam harum eum aliquam esse! Eius, quos tenetur? Quis,
            quidem!
          </div>
        </div>
      </div>
    </>
  );
}
