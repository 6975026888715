/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { useEffect, useState } from "react";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import classNames from "classnames";
import useDevices from "../../helper/use-devices";

interface CarouselProps {
  totalItems: number;
  children?: React.ReactNode; // children class = carousel-item
  className?: string;
}
export default function Carousel({
  totalItems,
  children,
  className
}: CarouselProps) {
  const { isMobileDevice, isTabletDevice, isDesktopDevice } = useDevices();
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ visible, setVisible ] = useState(1);
  const [ totalSlides, setTotalSlides] = useState(totalItems - 1);

  useEffect(() => {
    const _visible: number = isDesktopDevice ? 3 : 1;
    setVisible(_visible);
    setTotalSlides(Math.ceil(totalItems - _visible + 1));
  }, [isMobileDevice, isTabletDevice, isDesktopDevice]);

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 3000);
    return () => clearInterval(intervalId);
  }, [nextSlide]);

  return (
    <>
      <div
        className={classNames(
          "carousel",
          (isMobileDevice || isTabletDevice) && "carousel-vertical",
          className,
        )}
      >
        <div
          className="carousel-inner"
          style={{
            transform: isDesktopDevice
              ? `translateX(-${currentIndex * (100 / visible)}%)`
              : `translateX(-${currentIndex * (200 / totalItems)}%)`
          }}
        >
          {children}
        </div>
      </div>

      {/* Next, Previous */}
      {
        !isMobileDevice && (
          <>
            <NavigateBefore
              className="prev absolute left-[5%] top-[45%] text-4xl text-gray-500 cursor-pointer min-w-10 min-h-10"
              onClick={prevSlide}
            />
            <NavigateNext
              className="next absolute right-[5%] top-[45%] text-4xl text-gray-500 cursor-pointer min-w-10 min-h-10"
              onClick={nextSlide}
            />
          </>
        )
      }
    </>
  );
}