/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.css";
import { useLoadingContext } from "../../../context/loading";
import useGo from "../../../helper/use-go";
import { useParams } from "react-router-dom";
import { TourService } from "../../../api/tour";
import { RESPONSE_STATUS, ROUTE } from "../../../config";
import { cloneDeep } from "lodash";
import classNames from "classnames";
import BreadCrumb, { BreadCrumbItem } from "../../../components/breadcrumb";
import { displayMoney, displayUnitNumber } from "../../../helper";
import { Tour } from "../../../interface/model/tour";
import Rating from "../../../components/rating";
import { HelpRounded } from "@mui/icons-material";
import TourItineraries from "./component/tour-itinerary";
import TourDatesAndPrices from "./component/tour-dates-and-prices";
import TourHighlights from "./component/tour-highlight";
import TourAccommodation from "./component/tour-accommodation";
import ModalGetAQuote from "../../../components/modal-get-a-quote";
import { GetAQuoteForm } from "../../../interface/component/modal-get-a-quote";
import useDevices from "../../../helper/use-devices";

export default function DestinationTour() {
  const { isDesktopDevice } = useDevices();
  const { setLoading } = useLoadingContext();
  const go = useGo();
  const { id, programId } = useParams();

  const [tour, setTour] = useState<Tour>();
  const [country, setCountry] = useState<any>();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [modalGetAQuote, setModalGetAQuoteData] = useState<Tour>();
  const [openModalGetAQuote, setOpenModalGetAQuote] = useState<boolean>(false);

  useEffect(() => {
    id && programId && handleGetDestinationProgram();
  }, [id, programId]);

  const handleGetDestinationProgram = async () => {
    if (!id || !programId) return;

    const getById = async () => {
      setLoading(true);

      const service = TourService();
      const res = await service.getTourProgramById(id, programId);
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        if (res?.data) {
          setTour(cloneDeep(res?.data) || {});
          setCountry(cloneDeep(res?.parent));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getById();
  };

  const toggleModalGetAQuote = () => {
    setModalGetAQuoteData(tour);
    setOpenModalGetAQuote((prev) => prev = !prev);
  }

  const saveGetAQuote = (form: GetAQuoteForm) => {
    // console.log("saveGetAQuote : ", form);
  }

  const onChangeTab = (tab: number) => {
    setActiveTab(tab);
  }

  return (
    <>
      <div
        className={classNames(
          "destination-detail-container"
        )}
      >
        <BreadCrumb
          className={classNames(
            "bg-base-100 text-gray-800 mt-[70px] z-10",
            "lg:mt-[74.5px] w-full absolute",
          )}
        >
          <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
          <BreadCrumbItem
            title="Destination"
            onClick={() => go(ROUTE.DESTINATION)}
          />
          <BreadCrumbItem
            title={country?.title}
            onClick={() => go(`${ROUTE.DESTINATION}/${id}`)}
          />

          <BreadCrumbItem title={tour?.title || ""} isActive />
        </BreadCrumb>
      </div>

      {/*  */}
      <div className="w-11/12 m-auto pt-36 mb-10 lg:mb-20 cursor-default">
        {/* Header */}
        <section className="lg:my-10">
          <div className="lg:grid lg:grid-cols-5">
            {/* Title & Review */}
            <div className="text-left lg:col-span-3">
              {/* Review */}
              <div className="review-container hidden">
                {
                  tour?.rating && 
                  <Rating
                    rating={tour?.rating}
                    name={"rating-" + tour?.id}
                    className="w-48 mb-2"
                  />
                }

                <div className="text-lg ml-3 text-gray-500 font-semibold inline-block">
                  <span className="font-bold text-2xl">{tour?.rating}</span>
                  {" "}

                  {
                    tour?.review_count &&
                    <div>
                      <span className="font-bold">
                        { displayUnitNumber(tour?.review_count) }
                      </span>
                      {" "}
                      Independent Reviews
                    </div>
                  }
                </div>
              </div>

              {/* Title */}
              <h1
                className={classNames(
                  "text-gray-600 text-center text-3xl font-semibold w-full my-3",
                  "lg:text-5xl lg:my-5 lg:text-left lg:mx-0"
                )}
              >
                {tour?.title?.toUpperCase()}
              </h1>

              {/* Note */}
              <div className="text-center lg:text-left">
                <small
                  className={classNames(
                    "text-md font-semibold border-b-2 opacity-80",
                    "nst-text border-b-[#018181]",
                    "lg:text-lg lg:text-left"
                  )}
                >
                  {tour?.note}
                </small>
              </div>
            </div>

            {/* Price & Booking */}
            <div className="lg:text-right col-span-2 h-full flex flex-col lg:justify-between">
              <div className="lg:grid lg:grid-cols-2 mt-5">
                {/* Price */}
                <div className="flex-col lg:pr-3 text-2xl text-gray-500">
                  {
                    tour?.prices?.was ? (
                      <div className="price-was text-xl font-semibold">
                        Price{" "}
                        <s>฿{displayMoney(tour?.prices?.was)}</s>
                      </div>
                    ) : ""
                  }

                  <div className="price-normal font-bold mt-2">
                    From{" "}
                    <span className="text-4xl">฿{displayMoney(tour?.prices?.from || 0)}</span>
                  </div>
                </div>

                {/* Get a Quote */}
                <div className="flex-col px-10 pt-5 lg:pt-0">
                  <button
                    className={classNames(
                      "btn-booking btn-view-tour mt-2 flex-col",
                      "btn my-1 w-full rounded-3xl",
                      "nst-btn",
                      // "bg-blue-800 text-white",
                      // "hover:bg-white hover:text-blue-800 hover:border-blue-800"
                    )}
                    onClick={() => toggleModalGetAQuote()}
                  >
                    GET A QUOTE
                  </button>
                </div>
              </div>

              {/* Noted */}
              <div className="w-full lg:w-5/6 m-auto flex flex-col col-span-2 text-xs p-4 mt-2 text-left text-gray-500">
                <div className="flex items-center">
                  <HelpRounded className="relative" />
                  <span className="pl-2">To enquire in local currency, call or click on Easy Quote button and add your currency preference in the Additional Information box.</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Tabs */}
      <div
        className={classNames(
          // "hidden",
          "grid grid-cols-2 lg:grid-cols-4 shadow-sm cursor-pointer",
          "text-[0.75rem] text-gray-600 font-semibold",
          "lg:text-lg"
        )}
      >
        <div
          className={classNames(
            "pb-3",
            activeTab === 0 && "border-b-[#018181] border-b-[3px]"
          )}
          onClick={() => onChangeTab(0)}
        >
          <div className={classNames(
            "pb-2 border-r-[3px] border-r-gray-200",
          )}>
            ITINERARY
          </div>
        </div>

        <div
          className={classNames(
            "hidden lg:block",
            "pb-3 cursor-default",
            "text-gray-300",
            activeTab === 1 && "border-b-[#018181] border-b-[3px] transform-cpu"
          )}
          // onClick={() => onChangeTab(1)}
        >
          <div className="pb-2 border-r-[3px] border-r-gray-200">
            DATES & PRICES
          </div>
        </div>

        <div
          className={classNames(
            "pb-3",
            activeTab === 2 && "border-b-[#018181] border-b-[3px]"
          )}
          onClick={() => onChangeTab(2)}
        >
          <div className="pb-2 border-r-[3px] border-r-gray-200">
            { isDesktopDevice ? 'TOUR HIGHLIGHT' : 'HIGHLIGHT' }
          </div>
        </div>

        <div
          className={classNames(
            "hidden lg:block",
            "pb-3 cursor-default",
            "text-gray-300",
            activeTab === 3 && "border-b-[#018181] border-b-[3px]"
          )}
          // onClick={() => onChangeTab(3)}
        >
          <div className="pb-2">
            ACCOMMODATIONS
          </div>
        </div>
      </div>

      <div className="mt-2">
        {/* Tab 0 - Itinerary */}
        {
          activeTab === 0 && tour && (
            <TourItineraries
              data={tour}
            />
          )
        }

        {/* Tab 1 - Dates & Prices */}
        {
          activeTab === 1 && tour && (
            <TourDatesAndPrices
              data={tour}
            />
          )
        }

        {/* Tab 2 - Tour Highlight */}
        {
          activeTab === 2 && tour && (
            <TourHighlights
              data={tour}
            />
          )
        }

        {/* Tab 3 - Accommodations */}
        {
          activeTab === 3 && tour && (
            <TourAccommodation
              data={tour}
            />
          )
        }
      </div>


      {/* Get a Quote */}
      <ModalGetAQuote
        className="min-w-[90%] lg:min-w-[80%] pb-8"
        data={modalGetAQuote}
        isOpen={openModalGetAQuote}
        setIsOpen={() => setOpenModalGetAQuote(false)}
        onConfirm={(form: GetAQuoteForm) => saveGetAQuote(form)}
      />
    </>
  )
}