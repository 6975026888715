import apiService from "./api-service";
import { SubscribeForm } from "../interface/model/subscribe";

export const SubscribeService = () => {
  const api = apiService();
  const path = "/subscribe";

  return {
    subscribe: (payload: SubscribeForm): Promise<any> => {
      return api.post(`${path}`, payload);
    },
    unsubscribe: (payload: SubscribeForm): Promise<any> => {
      return api.put(`/unsubscribe`, payload);
    }
  }
}