export const RESPONSE_STATUS = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  NOT_FOUND: "not_found"
}

export const LOADING = {
  SPINNER: "spinner",
  DOTS: "dots",
  RING: "ring",
  BALL: "ball",
  BARS: "bars",
  INFINITY: "infinity",
}

export const COLOR = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  ACCENT: "accent",
  NEUTRAL: "neutral",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
}

export const DELAY = 500; // 0.5 second

export enum VALIDATE {
  REQUIRED = "This field is required.",
  EMAIL = "The email format is incorrect.",
}

export const MESSAGE_CONTACT = {
  GET_A_QUOTE_SUCCESS: "Get a quote successful. We have received your information and will get back to you as soon as possible. Thank you for you terest in our tour.",
  GET_A_QUOTE_ERROR: "Get a quote encountered an issue. Please submit your request again or contact us via the 'Contact Us' section. We apologize for the inconvenience.",
  CONTACT_SUCCESS: "ontact information has been successfully submitted. We have received your information and will get back to you as soon as possible. Thank you for reaching out.",
  CONTACT_ERROR: "System error. Unable to send contact information. We apologize for the inconvenience.",
}
