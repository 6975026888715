/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import "./style.css";
import classNames from "classnames";
import { useEffect, useState } from "react";

import { ROUTE } from "../../config/route";
import useGo from "../../helper/use-go";
import { useLocation } from "react-router-dom";
import { MenuData, SubMenuData } from "../../interface/layout";
import useScroll from "../../helper/use-scroll";
import { ExpandLess, ExpandMore, Phone, Menu, Close } from "@mui/icons-material";

export default function Header() {
  const go = useGo();
  const isScroll = useScroll();
  const location = useLocation();

  const [menuItems, setMenuItems] = useState<MenuData[]>();
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true);

  const [isFixedActiveBg, setIsFixedActiveBg] = useState<boolean>(false);

  const menuData: MenuData[] = [
    {
      element_id: "destinations",
      label: "Destinations",
      path: ROUTE.DESTINATION,
      isActive: true,
      isOpen: false,
      subMenu: [
        {
          id: 1,
          element_id: "destinations-thailand",
          label: "Thailand",
          description: "Exploring Thailand can be incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
          path: `${ROUTE.DESTINATION}/1`,
          // image: "/asset/images/mock/cover-menu-bg-1.png",
          isBgImage: true,
          isActive: false,
        },
        {
          id: 2,
          element_id: "destinations-vietnam",
          label: "Vietnam",
          description: "Exploring Vietnam can be incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
          path: `${ROUTE.DESTINATION}/2`,
          // image: "/asset/images/mock/cover-menu-bg-2.png",
          isBgImage: true,
          isActive: false,
        },
        {
          id: 3,
          element_id: "destinations-cambodia",
          label: "Cambodia",
          description: "Exploring Cambodia can be incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
          path: `${ROUTE.DESTINATION}/3`,
          // image: "/asset/images/mock/cover-menu-bg-3.png",
          isBgImage: true,
          isActive: false,
        },
        {
          id: 4,
          element_id: "destinations-laos",
          label: "Laos",
          description: "Exploring Laos can be incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
          path: `${ROUTE.DESTINATION}/4`,
          // image: "/asset/images/mock/cover-menu-bg-4.png",
          isBgImage: true,
          isActive: false,
        },
        {
          id: 5,
          element_id: "destinations-myanmar",
          label: "Myanmar",
          description: "Exploring Myanmar can be incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
          path: `${ROUTE.DESTINATION}/5`,
          // image: "/asset/images/mock/cover-menu-bg-5.png",
          isBgImage: true,
          isActive: false,
        },
        {
          id: 6,
          element_id: "destinations-indochina",
          label: "Indochina",
          description: "Exploring Indochina can be incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
          path: `${ROUTE.DESTINATION}/6`,
          // image: "/asset/images/mock/cover-menu-bg-6.png",
          isBgImage: true,
          isActive: false,
        },
      ],
    },
    {
      element_id: "services",
      label: "Services",
      path: ROUTE.SERVICE,
      isActive: true,
      isOpen: false,
      subMenu: [
        {
          id: 1,
          element_id: "tailor-made",
          label: "Tailor Made",
          description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
          path: `${ROUTE.SERVICE}/1`,
          // image: "/asset/images/mock/cover-menu-1.png",
          isActive: false,
        },
        {
          id: 2,
          element_id: "day-trips",
          label: "Day Trips",
          description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
          path: `${ROUTE.SERVICE}/2`,
          // image: "/asset/images/mock/cover-menu-2.png",
          isActive: false,
        },
        {
          id: 3,
          element_id: "group",
          label: "Group",
          description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
          path: `${ROUTE.SERVICE}/3`,
          // image: "/asset/images/mock/cover-menu-3.png",
          isActive: false,
        },
        {
          id: 4,
          element_id: "event",
          label: "Event",
          description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
          path: `${ROUTE.SERVICE}/4`,
          // image: "/asset/images/mock/cover-menu-5.png",
          isActive: false,
        },
      ]
    },
    {
      element_id: "about-us",
      label: "About Us",
      path: ROUTE.ABOUT_US,
      isActive: true,
      isOpen: false,
      subMenu: null,
    },
    {
      element_id: "contact-us",
      label: "Contact Us",
      path: ROUTE.CONTACT_US,
      isActive: true,
      isOpen: false,
      subMenu: null,
    },
  ];

  useEffect(() => {
    setFirstLoad(true);

    setIsFixedActiveBg(
      (location.pathname?.includes('destination') && location.pathname?.includes('tour')) ||
      location.pathname?.includes('unsubscribe')
    );
  }, [location]);

  useEffect(() => {
    isScroll && isFirstLoad && setFirstLoad(!isScroll);
  }, [isScroll]);

  useEffect(() => {
    const activeMenu: MenuData[] = [...menuData].filter((r) => r.isActive);
    setMenuItems([...activeMenu]);
  }, []);

  const renderMenuStyle = (): string => {
    let _class: string = "";
    if (isScroll || isFixedActiveBg) {
      _class = "fixed bg-white text-gray-900 shadow-md";
      if (!isFirstLoad) {
        _class += " animate__animated animate__fadeInDown";
      }
    } else {
      _class = "absolute text-white bg-transparent";
      if (!isFirstLoad) {
        _class += " animate__animated animate__fadeInUp";
      }
    }
    return _class;
  }

  const renderHoverStyle = (): string => {
    let _class: string = "";
    if (isScroll || isFixedActiveBg) {
      _class = "hover:border-solid hover:border-gray-800 hover:border-b-2";
    } else {
      _class = "hover:border-solid hover:border-white hover:border-b-2";
    }
    return _class;
  }

  const renderActiveStyle = (): string => {
    let _class: string = "";
    if (isScroll || isFixedActiveBg) {
      _class = "active border-solid border-gray-800 border-b-2";
    } else {
      _class = "active border-solid border-white border-b-2";
    }
    return _class;
  }

  const isActiveMenu = (path: string): boolean => {
    const active = location.pathname.startsWith(path);
    return path ? active : false;
  };

  const handleChangeMenu = (path: string) => {
    setIsOpenMobileMenu(false);
    setMenuItems([...menuData]);
    go(path);

    // fix click close mobile menu
    isOpenMobileMenu && document.getElementById("drawer-sidebar")?.click();
  };

  const handleToggleSubmenu = (menuList: MenuData[], menu: MenuData, isActive: boolean) => {
    menu.subMenu?.map(s => s.isActive = isActive);
    setMenuItems([...menuList]);
  }

  return (
    <>
      <div
        className={classNames(
          "navbar z-50 lg:pl-10 lg:pr-10 transition-transform will-change-transform min-h-[70px]",
          renderMenuStyle(),
        )}
      >
        {/* Logo - Mobile */}
        <div className="navbar-start hidden lg:flex">
          <label
            className="text-lg cursor-pointer font-bold"
            onClick={() => handleChangeMenu(ROUTE.HOME)}
          >
            NORTHERN SMILE TRAVEL
          </label>
        </div>

        {/* Logo - Mobile */}
        <div className={classNames("navbar-start w-full pl-2 lg:hidden")}>
          <label
            className="text-lg cursor-pointer font-bold"
            onClick={() => handleChangeMenu(ROUTE.HOME)}
          >
            NORTHERN SMILE TRAVEL
          </label>
        </div>

        {/* Menu - Desktop */}
        <div className="navbar-center min-h-[55px] hidden lg:flex">
          <ul className="menu menu-horizontal px-1 h-full ">
            {menuItems &&
              menuItems.map((menu: MenuData, index: number) => {
                const isShowSubMenu: boolean = menu.subMenu?.some(s => s.isActive) || false;

                return <div
                  key={index}
                  id={menu.element_id}
                  className={classNames(
                    "menu-item-wrapper",
                    // "hover:border-solid hover:border-white hover:border-b-2",
                    "min-h-[42.5px] cursor-pointer p-2 h-full",
                    renderHoverStyle(),
                    isActiveMenu(menu.path) && renderActiveStyle(),
                  )}
                  onMouseEnter={() => {
                    handleToggleSubmenu(menuItems, menu, true);
                  }}
                  onMouseLeave={() => {
                    handleToggleSubmenu(menuItems, menu, false);
                  }}
                >
                  <label
                    className={classNames(
                      "menu-label",
                      "ml-2 mr-2 cursor-pointer"
                    )}
                    onClick={() => {
                      handleChangeMenu(menu.path);
                      handleToggleSubmenu(menuItems, menu, false);
                    }}
                  >
                    {menu.label}
                    {menu.subMenu && (
                      <>
                        {isShowSubMenu ? (
                          <ExpandLess className="expand-menu animate__animated animate__fadeIn" />
                        ) : (
                          <ExpandMore className="expand-menu animate__animated animate__fadeIn" />
                        )}
                      </>
                    )}
                  </label>

                  {/* Sub menu - Desktop */}
                  {isShowSubMenu && menu.subMenu && (
                    <div
                      className={classNames(
                        "custom-sub-menu min-top w-1/3 min-h-80 z-10 shadow-md absolute hidden lg:grid",
                        "animate__animated animate__fadeIn",
                        isScroll && "bg-opacity-35",
                      )}
                    >
                      {menu.subMenu.map((subMenu: SubMenuData, subIndex: number) => (
                        <div
                          key={"sub-menu-" + subIndex}
                          className={classNames(
                            "custom-sub-menu-item",
                            "h-full min-h-20 mt-[1px]",
                            "bg-gray-50 text-sm text-[16px] text-gray-900 cursor-pointer p-[1px]",
                          )}
                          onClick={() => {
                            subMenu.path && handleChangeMenu(subMenu.path);
                          }}
                        >
                          {/* Bg image */}
                          {
                            subMenu?.isBgImage &&
                            <div
                              className={classNames(
                                "text-center h-full items-center flex justify-center",
                                "text-white hover:font-semibold hover:border-blue-800",
                                subMenu?.isBgImage && isActiveMenu(subMenu.path) && "text-white font-semibold text-[16px]",
                                !subMenu?.image && "nst-bg",
                              )}
                              style={subMenu?.image ? {"background": `url(${subMenu?.image})`} : {}}
                            >
                              {subMenu.label}
                            </div>
                          }

                          {/* Image */}
                          {
                            !subMenu?.isBgImage &&
                            <div
                              className={classNames(
                                "grid grid-cols-3 h-full p-[1px]",
                                "hover:text-[#018181] hover:font-semibold",
                                !subMenu?.isBgImage && isActiveMenu(subMenu.path) && "nst-text font-semibold text-[16px]",
                              )}
                            >
                              <div
                                className={classNames(
                                  !subMenu?.image && "nst-bg"
                                )}
                                style={subMenu?.image ? {"background": `url(${subMenu?.image})` } : {}}
                              ></div>
                              <div className="col-span-2 h-full items-center flex pl-5">{subMenu.label}</div>
                            </div>
                          }

                        </div>
                      ))}
                    </div>
                  )}
                </div>
              }
            )}
          </ul>
        </div>

        {/* End - Contact - Desktop */}
        <div className="navbar-end hidden lg:flex">
          <div className={classNames(
            "divider sm:divider-horizontal",
            !isScroll && !isFixedActiveBg && "before:bg-white after:bg-white",
            (isScroll || isFixedActiveBg) && "before:bg-gray-500 after:bg-gray-500"
          )}></div>
          <div className="rounded-box grid text-sm">
            <a href="tel:+6653289657" className="text-sm">
              <Phone />
              +66 53289 657
            </a>
            <label htmlFor="email" className="text-xs">
              Email :{" "}
              <a href="mailto:info@northernsmiletravel.com">
                info@northernsmiletravel.com
              </a>
            </label>
          </div>
        </div>

        {/* End - Toggle Menu - Mobile */}
        <div className="mobile-menu-container pr-1 lg:hidden">
          {/* Toggle menu */}
          <input id="my-drawer" type="checkbox" className="drawer-toggle" />
          <label htmlFor="my-drawer">
            {!isOpenMobileMenu ? (
              <Menu onClick={() => setIsOpenMobileMenu(true)} />
            ) : (
              <Close onClick={() => setIsOpenMobileMenu(false)} />
            )}
          </label>

          {/* Menu */}
          <div
            className={classNames(
              "drawer-side",
              isOpenMobileMenu ? "drawer-side-open" : "drawer-side-closed",
            )}
          >
            {/* <div className="w-10/12 h-screen" id="drawer-sidebar"> */}
              <label
                id="drawer-sidebar"
                htmlFor="my-drawer"
                aria-label="close sidebar"
                className="drawer-overlay"
                onClick={() => setIsOpenMobileMenu(false)}
              ></label>

              <ul className="menu pt-10 bg-base-200 text-base-content h-full w-10/12 md:w-3/5 p-4 text-left">
                {menuItems &&
                  menuItems.map((menu: MenuData, index: number) => {
                    return !menu?.subMenu ? (
                      <ol
                        key={"mobile-menu-" + index}
                        className="my-2"
                      >
                        <a
                          className={classNames(
                            "custom-mobile-menu-item",
                            "w-full mt-1 z-10 divide-y lg:hidden",
                            "hover:text-[#018181]",
                            isActiveMenu(menu.path) &&
                              "font-bold text-[#018181] border-b-[#018181]"
                          )}
                          onClick={() =>
                            handleChangeMenu(menu.path)
                          }
                        >
                          {menu.label}
                        </a>
                      </ol>
                    ) : (
                      <ol
                        key={"mobile-menu-" + index}
                        className="text-left"
                      >
                        <a
                          className={classNames(
                            "custom-mobile-menu-item",
                            "w-full mt-1 z-10 divide-y lg:hidden",
                            "hover:text-[#018181]",
                            isActiveMenu(menu.path) &&
                              "font-bold text-[#018181] border-b-[#018181]",
                          )}
                          onClick={() =>
                            handleChangeMenu(menu.path)
                          }
                        >
                          {menu.label}
                        </a>
                        <ul className="my-3">
                          {menu.subMenu &&
                            menu.subMenu.map(
                              (subMenu: SubMenuData, subIndex: number) => (
                                <ol
                                  key={"mobile-sub-menu-" + subIndex}
                                  className={classNames(
                                    "hover:text-[#018181] text-left py-2 ml-2 pl-5 border-l-gray-200 border-l-[1px]",
                                    isActiveMenu(subMenu.path) &&
                                      "text-[#018181] border-b-[#018181]",
                                  )}
                                  onClick={() =>
                                    handleChangeMenu(subMenu.path)
                                  }
                                >
                                  <a>{subMenu.label}</a>
                                </ol>
                              )
                            )}
                        </ul>
                      </ol>
                    )
                })}
              </ul>

              {/* Contact */}
              <div
                className={classNames(
                  "custom-mobile-menu-contact w-10/12 text-gray-700",
                  "absolute bottom-5 pt-5",
                )}
              >
                <div className="grid text-sm w-full">
                  <a href="tel:+6653289657" className="text-sm">
                    <Phone className="custom-phone-icon" />
                    <span className="hover:text-[#018181]"></span>
                    +66 53289 657
                  </a>
                  <label htmlFor="email" className="text-xs">
                    Email :{" "}
                    <a
                      href="mailto:info@northernsmiletravel.com"
                      className="hover:text-[#018181]"
                    >
                      info@northernsmiletravel.com
                    </a>
                  </label>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>

      </div>
    </>
  );
}
