import classNames from "classnames";
import useGo from "../../helper/use-go";

interface BannerFullProps {
  title?: string;
  description?: string;
  link?: string;
  image?: string;
  className?: string;
}

export default function BannerFull({
  title,
  description,
  link,
  image,
  className,
}: BannerFullProps) {
  const go = useGo();

  return (
    <div
      className={classNames(
        "h-[375px] rounded-2xl bg-zinc-600",
        className
      )}
    >
      <div className={classNames(
        "detail right-0 lg:w-2/4",
        "lg:absolute lg:pt-16 lg:pr-32 lg:text-right"
      )}>
        {/* Title */}
        {title && (
          <h1 className={classNames(
            "text-white text-[2rem] mb-4 pt-10 font-semibold",
            "lg:text-[3.5rem] lg:pt-0",
          )}>
            {title}
          </h1>
        )}

        {/* Description */}
        {description &&
          <p className={classNames(
            "text-white text-[0.9rem] px-2",
            "lg:text-[1rem] lg:px-0"
          )}>
            {description}
          </p>
        }
      </div>

      <div className={classNames(
        "flex justify-center w-full transform translate-y-20",
        "lg:relative lg:top-[70%] lg:left-20 lg:text-left lg:translate-y-5 lg:justify-start",
        // "absolute bottom-5 left-1/2 transform -translate-x-1/2",
      )}>
        {/* Link */}
        {link && (
          <button
            type="button"
            className="btn rounded-3xl"
            onClick={() => go(link)}
          >
            <span className="font-bold">FIND OUT MORE</span>
          </button>
        )}
      </div>
    </div>
  );
}
