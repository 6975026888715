import { useLocation, useNavigate } from "react-router-dom";
import { useLoadingContext } from "../context/loading";
import { scrollToTop } from "./scroll";

export default function useGo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoadingContext();

  const go = (path: string) => {
    if (path && location.pathname !== path) {
      setLoading(true);
      navigate(path);
      scrollToTop();
    }
  };

  return go;
}
