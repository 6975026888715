/* eslint-disable react-hooks/exhaustive-deps */
import { Search } from "@mui/icons-material";
import classNames from "classnames";
import useGo from "../../helper/use-go";
import "./style.css";
import { useDebounce } from "use-debounce";
import { useEffect, useRef, useState } from "react";

interface CoverPageProps {
  image?: string;
  title?: string;
  label?: string;
  detail?: string;
  detailPosition?: "left" | "center" | "right";
  viewLink?: string;
  heightDetail?: string;
  isHideSearch?: boolean;
  isMiniScreen?: boolean;
  className?: string;
  isAnimate?: boolean;
  inputSearch?: (value: string) => void;
  onSearch?: () => void;
}

export default function CoverPage({
  image,
  title,
  label,
  detail,
  detailPosition = "center",
  viewLink,
  heightDetail = "lg:h-2/4",
  isHideSearch,
  isMiniScreen,
  className,
  isAnimate,
  inputSearch,
  onSearch,
}: CoverPageProps) {
  const go = useGo();
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState<string>("");
  const [search] = useDebounce(searchText, 250);

  useEffect(() => {
    inputSearch && inputSearch(search);
  }, [search]);

  const renderClass = (): string => {
    let _class: string = "";

    switch (detailPosition) {
      case "left":
        _class = `
          lg:left-0 lg:ml-40
          md:left-0 md:ml-10
          text-left
        `;
        break;
      case "center":
        _class = ``;
        break;
      case "right":
        _class = `
          lg:right-0 lg:mr-40
          md:right-0 md:mr-10
          md:mr-10
          text-right
        `;
        break;
    }
    return _class;
  };

  const renderAnimate = (): string => {
    let _animate: string = "";

    switch (detailPosition) {
      case "left":
        _animate = `fade-right`;
        break;
      case "center":
        _animate = `fade-up`;
        break;
      case "right":
        _animate = `fade-left`;
        break;
    }

    return _animate;
  };

  return (
    <>
      <div
        className={classNames(
          "cover-container pt-0",
          !image && "nst-bg-color",
          className
        )}
        style={
          image
            ? {
                backgroundImage: `url(${image})`,
              }
            : {
                backgroundColor: `#018181` 
              }
        }
      >
        <div
          className={classNames(
            "cover-image hero",
            "min-h-screen",
            isMiniScreen && "lg:min-h-[400px]"
          )}
        >
          {/* Title & Label & Detail */}
          {(title || label || detail) && (
            <div
              className={classNames(
                "cover-detail text-white",
                "lg:absolute lg:w-1/3",
                "md:absolute",
                "pr-4 lg:pr-0",
                `${heightDetail}`,
                renderClass()
              )}
              data-aos={isAnimate ? renderAnimate() : "fade"}
            >
              {/* Title */}
              {title && (
                <h1
                  className={classNames(
                    "font-semibold mb-4 leading-snug text-[3.5rem] px-2",
                    "lg:text-[5rem] lg:px-0",
                    isMiniScreen && "pt-10"
                  )}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: title?.replaceAll("\n", "<br/>"),
                    }}
                  />
                </h1>
              )}

              {/* Label */}
              {label && (
                <p
                  className={classNames(
                    "text-[1.5rem] font-semibold leading-snug mb-2 px-2",
                    "lg:text-[1.75rem] lg:px-0"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: label?.replaceAll("\n", "<br/>"),
                  }}
                ></p>
              )}

              {/* Detail */}
              {detail && (
                <p
                  className="text-[1rem] leading-snug px-2 lg:px-0"
                  dangerouslySetInnerHTML={{
                    __html: detail?.replaceAll("\n", "<br/>"),
                  }}
                ></p>
              )}
            </div>
          )}

          {/* Content */}
          {!isHideSearch && (
            <div
              className={classNames(
                "cover-content",
                "hero-content text-neutral-content text-center bg-white absolute -bottom-7 shadow-lg rounded-md w-3/5 min-w-80",
                isMiniScreen && "lg:bottom-40"
              )}
              data-aos={isAnimate ? "fade-up" : "fade"}
            >
              <div className={classNames("content", "w-4/5 py-5")}>
                <div className={classNames("search-content", "mb-5 relative")}>
                  <input
                    type="text"
                    ref={inputRef}
                    placeholder="What are you looking for? Q"
                    className={classNames(
                      "input input-search-content text-gray-700",
                      "w-full input-bordered rounded-3xl bg-gray-100 relative inline-block lg:pr-48"
                    )}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchText(event.target.value)
                    }
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        if (inputRef.current) {
                          inputRef.current.blur();
                        }
                        onSearch && onSearch();
                      }
                    }}
                  />
                  <button
                    type="button"
                    name="search"
                    className={classNames(
                      "btn-search-content",
                      "absolute inset-y-0 right-0 flex items-center px-4",
                      "w-1/5 rounded-3xl btn nst-btn",
                    )}
                    onClick={onSearch}
                  >
                    <span className="lg:hidden">
                      <Search />
                    </span>
                    <span className="hidden lg:block">Search</span>
                  </button>
                </div>
                {viewLink && (
                  <label
                    className="text-gray-800 cursor-pointer text-sm hover:font-semibold nst-link"
                    htmlFor="view-all-tour"
                    onClick={() => go(viewLink)}
                  >
                    <u>View All Tours</u>
                  </label>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
