/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import "./style.css";
import { useEffect } from "react";
import { useLoadingContext } from "../../context/loading";
import { DELAY } from "../../config/constant";
import useGo from "../../helper/use-go";
import { ROUTE } from "../../config/route";
import { Home } from "@mui/icons-material";

export default function NotFound() {
  const go = useGo();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, DELAY);
  }, []);

  return (
    <div className={classNames("not-found w-screen h-screen")}>
      <div className="text-8xl">
        4<span className="text-blue-600">0</span>4
      </div>
      <div className="mt-3 text-md text-2xl">
        N<span className="text-blue-600">o</span>t
        {" "}
        F<span className="text-blue-600">o</span>und
      </div>

      <button
        type="button"
        className="btn btn-default mt-6 h-9 min-h-6 rounded-none border-gray-200"
        onClick={() => go(ROUTE.HOME)}
      >
        <Home /> HOME
      </button>
    </div>
  );
}
