import numeral from "numeral";

export const displayMoney = (value: number | string): string => {
  return numeral(+value).format("0,0[.]00");
};

export const displayFirstUpperCase = (value: string): string => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return "";
};

export const displayUnitNumber = (value: number): string => {
  let unitNumber: string = "";

  if (value < 1000) {
    unitNumber = `${value}`;
  } else if (value >= 1000 && value < 1000000) {
    unitNumber = `${value / 1000}K`;
  } else if (value >= 1000000) {
    unitNumber = `${value / 1000000}M`;
  }

  return unitNumber;
}
