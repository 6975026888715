import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "animate.css";
import Aos from 'aos';
import 'aos/dist/aos.css';
Aos.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  // document.getElementById("root")!
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
