/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RESPONSE_STATUS, ROUTE } from "../../config";
import { SubscribeService } from "../../api/subscribe";
import { useLoadingContext } from "../../context/loading";
import useGo from "../../helper/use-go";
import Modal from "../../components/modal";
import { CheckCircleOutlineOutlined, WarningAmberOutlined, ErrorOutlineRounded } from '@mui/icons-material';

export default function Unsubscribe() {
  const go = useGo();
  const { setLoading } = useLoadingContext();
  const { email } = useParams();

  const [form, setForm] = useState({
    email: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalConfirmData, setModalConfirmData] = useState<any>({});
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    email && setForm({ email });
  }, [email]);

  const submitForm = async () => {
    if (!form.email) {
      return;
    }

    setIsLoading(true);
    setLoading(true);

    // Call api
    const getAQuote = async () => {
      const service = SubscribeService();
      const res = await service.unsubscribe(form);
      if (res && res.status !== RESPONSE_STATUS.ERROR) {
        setIsLoading(false);
        setLoading(false);
        setModalConfirmData({
          status: res.status,
          message: res.message
        });
        setOpenModalConfirm(true);
      } else {
        setIsLoading(false);
        setLoading(false);
        setModalConfirmData({
          status: res.status,
          message: res.error.message.email[0]
        });
        setOpenModalConfirm(true);
      }
    }

    getAQuote();
  }

  const leavePage = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      go(ROUTE.HOME);
    }, 3000);
  }

  return (
    <>
      <div className="unscribe bg-white text-gray-800 pt-[35vh] w-full">
        <h3 className="font-semibold w-4/5 lg:w-2/5 m-auto text-2xl lg:text-3xl mt-5 mb-10 text-gray-700">
          Unsubscribe Confirmation
        </h3>
        <div className="form center relative left-0 right-0 w-4/5 lg:w-2/5 h-2/4 min-w-40 m-auto p-10 shadow-lg rounded-md">
          <div className="email">
            <input
              type="text"
              className={classNames(
                "input input-bordered w-full mb-3 lg:mb-0"
              )}
              placeholder="First Name *"
              value={form.email}
              disabled
            />
          </div>

          <div className="action m-auto mt-10">
            <button
              type="button"
              className={classNames(
                "btn rounded-3xl min-w-40",
                "nst-btn"
              )}
              onClick={submitForm}
              disabled={isLoading}
            >
              UNSUBSCRIBE
            </button>
          </div>
        </div>
      </div>

      <Modal
        title=""
        titlePosition="center"
        titleBorder={false}
        isOpen={openModalConfirm}
        setIsOpen={(value: boolean) => setOpenModalConfirm(value)}
        btnClose="Close"
        onClose={() => openModalConfirm && leavePage()}
      >
        <div className="icon my-5">
          {
            modalConfirmData?.status === "success" && (
              <CheckCircleOutlineOutlined style={{ fontSize: '60px' }} className="text-[#018181]" />
            )
          }
          {
            modalConfirmData?.status === "warning" && (
              <WarningAmberOutlined style={{ fontSize: '60px' }} className="text-yellow-400" />
            )
          }
          {
            modalConfirmData?.status === "error" && (
              <ErrorOutlineRounded style={{ fontSize: '60px' }} className="text-red-400" />
            )
          }
        </div>
        <div className="text-xl font-semibold mb-10">
          <span dangerouslySetInnerHTML={{__html: modalConfirmData?.message?.replaceAll('\n','<br/>')}}></span>
        </div>
      </Modal>
    </>
  )
}