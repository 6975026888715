import { useEffect, useState } from "react";
import { Tour } from "../../interface/model/tour";
import "./style.css";
import classNames from "classnames";
import { Apartment, CalendarMonthOutlined, Language, Search, SellRounded } from "@mui/icons-material";
import { displayMoney } from "../../helper/display";
import Rating from "../rating";
import useGo from "../../helper/use-go";

interface CardTourHorizontalProps {
  className?: string;
  data: Tour;
  index: number;
  isRating?: boolean;
  link?: string;
  getAQuote?: (value: Tour) => void;
}

export default function CardTourHorizontal({
  className,
  data,
  index,
  isRating,
  link,
  getAQuote,
}: CardTourHorizontalProps) {
  const go = useGo();

  const [tour, setTour] = useState<Tour>();

  useEffect(() => {
    data && setTour(data);
  }, [data]);

  return (
    <div className={classNames(
      "my-5",
      className
    )}>
      {
        tour && (
          <div className={classNames(
            "card",
            "bg-base-100 lg:mx-2 w-full min-h-80 shadow-lg my-5 lg:my-0 m-auto",
            "grid grid-cols-12"
          )}>
            {/* Image */}
            <div
              className="image-container col-span-3"
              style={{
                backgroundImage: `url(${tour?.image_cover_h})`,
              }}
            >

              {/* tag */}
              {tour.discount > 0 && (
                <div className={classNames("sale-tag-container", "w-full")}>
                  <div className={classNames(
                    "sale-tag",
                    "absolute py-1 px-5 top-5",
                    // "bg-blue-900 text-white"
                    "nst-bg text-white",
                  )}>
                    <SellRounded className="mr-1" style={{width: "1rem", height: "1rem"}} />
                    Save up to {displayMoney(tour?.discount)}฿
                  </div>
                </div>
              )}

              {/* Image horizontal */}
              {/* <img src={tour?.image_cover_h} alt={`image-tour-h-${tour?.title}-${index}`} /> */}

              {/* quick view */}
              <button
                type="button"
                className={classNames(
                  "btn-quick-view",
                  "btn nst-text",
                  "absolute left-3 bottom-3 min-h-3 max-h-10",
                )}
                onClick={() => link && go(link)}
              >
                <Search style={{width: "1.3rem", height: "1.3rem"}} />
                Quick View
              </button>
            </div>

            {/* Detail */}
            <div className="detail-container col-span-6 py-5 px-10">
              <div className="grid grid-cols-3">
                {/* With rating */}
                {
                  isRating && (
                    <>
                      {/* Rating */}
                      <Rating
                        rating={tour?.rating}
                        name={"rating-" + index}
                        className="w-60 mb-2 col-span-2"
                      />

                      {/* Travel type */}
                      <div className="pt-2">
                        <div className="badge rounded-md min-h-6 px-2 py-1 bg-emerald-100 nst-text max-h-8 text-xs max-w-60">
                          {tour?.travel_type}
                        </div>
                      </div>
                    </>
                  )
                }

                {/* No rating */}
                {
                  !isRating && (
                    <>
                      <div className="col-span-2"></div>

                      {/* Travel type */}
                      <div className="pt-2">
                        <div className="badge rounded-md min-h-6 px-2 py-1 bg-emerald-100 nst-text max-h-8 text-xs max-w-60">
                          {tour?.travel_type}
                        </div>
                      </div>
                    </>
                  )
                }

                {/* Title */}
                <div className="col-span-3 mb-5">
                  <h1 className="text-3xl py-4 font-semibold text-gray-700 text-left">
                    {tour?.title}
                  </h1>
                </div>

                {/* Includes */}
                <div className="includes-item flex pr-5" style={{fontSize: "12px"}}>
                  <CalendarMonthOutlined
                    className="text-gray-600"
                    style={{width: "1.25rem", height: "1.25rem"}}
                  />
                  <span className="flex-col text-right w-full pt-[2px]">
                    {tour?.travel_period?.days} Days {tour?.travel_period?.nights} Nights
                  </span>
                </div>

                <div className="includes-item flex pr-5" style={{fontSize: "12px"}}>
                  <Apartment
                    className="text-gray-600"
                    style={{width: "1.25rem", height: "1.25rem"}}
                  />
                  <span className="flex-col text-right w-full pt-[2px]">
                    {tour?.locations} Locations
                  </span>
                </div>

                <div className="includes-item flex pr-5" style={{fontSize: "12px"}}>
                  <Language
                    className="text-gray-600"
                    style={{width: "1.25rem", height: "1.25rem"}}
                  />
                  <span className="flex-col text-right w-full pt-[2px]">
                    {tour?.countries} Country
                  </span>
                </div>

                {/* Detail */}
                <span className="text-sm text-justify col-span-3 mt-7 line-clamp-5">
                  {tour?.detail}
                </span>
                
              </div>

            </div>

            {/* Prices & Action */}
            <div className="price-container col-span-3 rounded-r-lg flex items-center">
              <div className="w-5/6 h-5/6 m-auto bg-gray-100 rounded-lg flex items-center">
                <div className="px-5">
                  {
                    tour?.prices?.was ? (
                      <p className="text-xs mb-1">
                        Was{" "}
                        <span className="font-semibold text-sm">
                          <s>฿{displayMoney(tour?.prices?.was)}</s>
                        </span>
                      </p>
                    ) : ""
                  }

                  <p>
                    From{" "}
                    <span className="text-2xl font-semibold">
                      ฿{displayMoney(tour?.prices?.from)}
                    </span>
                  </p>

                  <button
                    className={classNames(
                      "btn-booking btn-view-tour mt-5 flex-col",
                      "btn my-1 w-full rounded-3xl",
                      "nst-btn-outline"
                      // "bg-white text-blue-800 border-blue-800",
                      // "hover:bg-blue-800 hover:text-white"
                    )}
                    onClick={() => link && go(link)}
                  >
                    VIEW TOUR
                  </button>

                  <button
                    className={classNames(
                      "btn-booking btn-get-a-quote mt-2 flex-col",
                      "btn my-1 w-full rounded-3xl",
                      "nst-btn",
                      // "bg-blue-800 text-white",
                      // "hover:bg-white hover:text-blue-800 hover:border-blue-800"
                    )}
                    onClick={() => getAQuote && getAQuote(tour)}
                  >
                    GET A QUOTE
                  </button>
                </div>

              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}