import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import classNames from "classnames";
import { useLoadingContext } from "../context/loading";
import Loading from "../components/loading";
import { COLOR } from "../config/constant";

export default function Layout() {
  const { loading } = useLoadingContext();

  return (
    <>
      <Header />
      <div className={classNames(
        "outlet-wrapper min-h-screen"
      )}>
        <div className="outlet-inner-wrapper">
          <Outlet />
        </div>
      </div>
      <Footer />

      {/* Loading */}
      <div className={classNames(
        "content text-center align-middle"
      )}>
        {loading && <Loading color={COLOR.PRIMARY} />}
      </div>
    </>
  );
}
