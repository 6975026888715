import { RESPONSE_STATUS } from "../config";
// import apiService from "./api-service";

export const serviceService = () => {
  // const api = apiService();
  // const path = "/service";

  return {
    getServices: (payload: any): Promise<any> => {
      // return api.get(`${path}s`, payload);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockServices
      })
    },
    getServiceById: (id: any): Promise<any> => {
      // return api.get(`${path}/${id}`);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetServiceById(id)
      })
    }
  }
}

const mockServices: any[] = [
  {
    id: 1,
    title: "Tailor-Made",
    description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 11,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 12,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 13,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
  {
    id: 2,
    title: "Day Trips",
    description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 21,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 22,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 23,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
  {
    id: 3,
    title: "Group",
    description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 31,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 32,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 33,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
  {
    id: 4,
    title: "Event",
    description: "Out Tour Styles allow you to discover remarkable destinations in different ways. Whether you want to explore several countries in one go, take the road less travelled, or explore at a more relaxed pace, we have a tour style for you.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 41,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 42,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 43,
        title: "THAILAND EXPLORE",
        description: "Exploring Thailand can be an incredible experience, as the country offers a diverse range of attractions, from bustling cities to serene beaches, lush jungles, and rich cultural heritage.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
];

const mockGetServiceById = (id: number | string) => {
  const detail = mockServices.find(r => +r.id === +id) || null;
  return detail;
}