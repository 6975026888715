import classNames from "classnames";

interface BannerMiniProps {
  title?: string;
  image?: string;
  onClick?: () => void;
  className?: string;
}

export default function BannerMini({
  title,
  image,
  onClick,
  className,
}: BannerMiniProps) {
  return (
    <div
      className={classNames(
        "h-[220px] rounded-2xl",
        "nst-bg",
        // "bg-gradient-to-br from-cyan-200 to-blue-800",
        onClick && "cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      <div className={classNames(
        "detail absolute w-full pt-12 justify-center align-middle",
        "lg:w-2/4 lg:pr-32 lg:text-right right-0"
      )}>
        {/* Title */}
        {title && (
          <h1 className="text-white text-[2.5rem] lg:text-[3.5rem] mb-4 font-semibold">
            {title}
          </h1>
        )}
      </div>
    </div>
  )
}