import classNames from "classnames";
import { Tour, TourItinerary } from "../../../../../interface/model/tour";
import { useEffect, useState } from "react";
import { Apartment, CalendarMonthOutlined, DirectionsWalk, Language, LocalPrintshop } from "@mui/icons-material";
import { scrollToTop } from "../../../../../helper";
import useDevices from "../../../../../helper/use-devices";

interface TourItinerariesProps {
  className?: string;
  data?: Tour;
}

export default function TourItineraries({
  className,
  data,
}: TourItinerariesProps) {
  const { isDesktopDevice } = useDevices();
  const [itineraries, setItineraries] = useState<TourItinerary[]>();
  const [activeCollapse, setActiveCollapse] = useState<number>(0);

  useEffect(() => {
    setItineraries(data?.itineraries);
  }, [data]);

  const onChangeCollapse = (index: number) => {
    setActiveCollapse(index);
  }

  return (
    <div
      className={classNames(
        "mt-10",
        className
      )}
    >
      {/* Header */}
      <div className="header-container mb-10">
        <div className="lg:grid lg:grid-cols-5">
          {/* Image */}
          <div className="lg:col-span-2 px-2 lg:px-0">
            <img
              src={data?.image}
              alt={`tour-itinerary-${data?.title}`}
            />
          </div>

          {/* Icon */}
          <div className="lg:col-span-3 px-2 lg:pl-10 pt-10">
            <div className="flex items-center space-x-1 lg:space-x-2 text-sm">
              <div className="w-28 h-28 rounded-md bg-base-300 flex flex-col items-center justify-center">
                <CalendarMonthOutlined style={{ width: '35px', height: '35px' }} />
                <span className="text-xs mt-2">
                  {data?.travel_period?.days}
                  {" "} Days {" "}
                  {data?.travel_period?.nights}
                  {" "} Nights
                </span>
              </div>
              <div className="w-28 h-28 rounded-md bg-base-300 flex flex-col items-center justify-center">
                <Apartment style={{ width: '35px', height: '35px' }} />
                <span className="text-xs mt-2">
                  {`${data?.locations} Locations`}
                </span>
              </div>
              <div className="w-28 h-28 rounded-md bg-base-300 flex flex-col items-center justify-center">
                <Language style={{ width: '35px', height: '35px' }} />
                <span className="text-xs mt-2">
                  {`${data?.countries} Countries`}
                </span>
              </div>
              <div className="w-28 h-28 rounded-md bg-base-300 flex flex-col items-center justify-center">
                <DirectionsWalk style={{ width: '35px', height: '35px' }} />
                <span className="text-xs mt-2">
                  Leisurely
                </span>
              </div>
            </div>

            {/* Short detail */}
            <div className="my-5 lg:my-10 text-left">
              <h2 className="text-2xl lg:text-4xl font-semibold text-gray-600">
                <span
                  dangerouslySetInnerHTML={{__html: data?.short_detail || ""}}
                ></span>
              </h2>
            </div>

            {/* Detail */}
            <div className="mt-5 text-left">
              <span className="text-md lg:text-lg text-gray-600">
                {data?.detail}
              </span>
            </div>

            {/* Print */}
            <div className="mt-10 lg:mt-5 text-center lg:text-left">
              <button
                type="button"
                className="nst-text text-2xl"
              >
                <LocalPrintshop style={{ width: '40px', height: '40px' }} />
                  {" "}
                  Print Itinerary
              </button>
            </div>
          </div>

        </div>
      </div>

      {/* Itinerary */}
      <div className="itinerary-container w-11/12 lg:w-10/12 mx-auto my-10 lg:my-20 text-left">
        {
          itineraries && itineraries?.map((item: TourItinerary, index: number) => (
            <div
              tabIndex={index}
              key={`${data?.title}-itinerary-day-${item?.day}`}
              className={classNames(
                "collapse collapse-arrow bg-white shadow-sm border my-2",
                activeCollapse === index && "collapse-open",
              )}
              onClick={() => onChangeCollapse(index)}
            >
              <div className="collapse-title font-semibold flex justify-between items-center shadow-sm">
                {/* Day, Title */}
                <div className="text-xl lg:text-3xl">
                  Day {item?.day}
                  {
                    isDesktopDevice && (
                      <span className="text-xl nst-text pl-10">
                        {item?.title}
                      </span>
                    )
                  }
                </div>
                {/* Collapse, Expand */}
                <div className="text-md lg:text-xl nst-text border-b-[#018181] border-b-2">
                  {activeCollapse === index ? "Collapse" : "Expand"}
                </div>
              </div>

              {/* Detail */}
              <div className="collapse-content">
                {/* Desktop */}
                <div className="hidden lg:flex lg:flex-col py-4 text-left text-gray-500">
                  <div className="flex items-center">
                    <img
                      src={item?.image}
                      alt={`itinerary-day-${item?.day}`}
                      className="max-w-80 h-auto"
                    />
                    <span className="pl-5">
                      {item?.detail}
                    </span>
                  </div>
                </div>

                {/* Mobile */}
                <div className="lg:hidden py-4 text-left text-gray-500">
                  <div className="items-center">
                    <img
                      src={item?.image}
                      alt={`itinerary-day-${item?.day}`}
                      className="max-w-80 h-auto"
                    />
                    <p className="pt-4">
                      {item?.detail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      {/* Back to top */}
      <div className="submit text-center mb-16">
        <button
          type="button"
          className={classNames(
            "btn rounded-3xl min-w-40",
            "nst-btn",
            // "bg-blue-800 text-white",
            // "hover:text-blue-800 hover:bg-white hover:border-blue-800",
          )}
          onClick={() => scrollToTop()}
        >
          Back on Top
        </button>
      </div>

    </div>
  )
}