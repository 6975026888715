/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import "./style.css";
import classNames from "classnames";

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  modalBoxClass?: string;
  title: string;
  titlePosition?: 'left' | 'center' | 'right';
  titleBorder?: boolean;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
  btnConfirmClass?: string;
  btnConfirm?: string;
  onConfirm?: () => void;
  btnCancelClass?: string;
  btnCancel?: string;
  onCancel?: () => void;
  btnCloseClass?: string;
  btnClose?: string;
  onClose?: () => void;
  noBackdropClose?: boolean;
  disableBtn?: boolean;
}

export default function Modal({
  children,
  className,
  modalBoxClass,
  title,
  titlePosition = 'left',
  titleBorder = true,
  isOpen,
  setIsOpen,
  btnConfirmClass,
  btnConfirm,
  onConfirm,
  btnCancelClass,
  btnCancel,
  onCancel,
  btnCloseClass,
  btnClose,
  onClose,
  noBackdropClose,
  disableBtn,
}: ModalProps) {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      ref.current?.showModal();
    } else {
      close();
    }
  }, [isOpen]);

  const close = () => {
    setIsOpen && setIsOpen(false);
    onClose && onClose();
    ref.current?.close();
  };

  const confirm = () => {
    onConfirm && onConfirm();
  }

  return (
    <dialog ref={ref} className={classNames(
      "modal",
      className
    )}>
      <div className={classNames(
        "modal-box",
        modalBoxClass
      )}>
        {
          title && (
            <div className={classNames(
              "modal-header my-5",
              titleBorder && "border-b-2"
            )}>
              <div className={classNames(
                "modal-title font-bold text-2xl text-gray-800 align-middle",
                `text-${titlePosition}`,
                titleBorder && "pb-5",
              )}>
                <div className="w-10/12 mx-auto">
                  <span dangerouslySetInnerHTML={{__html: title}}></span>
                </div>
              </div>

              {/* <form method="dialog" className="modal-backdrop">
                <div
                  className="close-modal"
                  onClick={close}
                  ref={defaultFocus}
                  tabIndex={0}
                >
                  <div className="material-symbols-rounded">close</div>
                </div>
              </form> */}
            </div>
          )
        }

        <div className="modal-body">
          {children}
        </div>

        <div className="modal-footer flex">
          {/* Close */}
          {onClose && btnClose && (
            <div className="action-left w-full">
              <button
                type="button"
                aria-label="close"
                className={classNames(
                  "btn-close",
                  "btn w-full max-w-40 rounded-3xl mx-1",
                  btnCloseClass,
                )}
                onClick={close}
                disabled={disableBtn}
              >
                {btnClose}
              </button>
            </div>
          )}

          {/* Cancel and Confirm */}
          {
            ((onCancel && btnCancel) || (onConfirm && btnConfirm)) && (
              <div className="action-right w-full">
                {onCancel && btnCancel && (
                  <button
                    type="button"
                    aria-label="cancel"
                    className={classNames(
                      "btn-cancel",
                      "btn btn-neutral w-full max-w-40 rounded-3xl mx-1",
                      "hover:bg-gray-100 hover:text-neutral",
                      btnCancelClass,
                    )}
                    onClick={close}
                    disabled={disableBtn}
                  >
                    {btnCancel}
                  </button>
                )}

                {onConfirm && btnConfirm && (
                  <button
                    type="button"
                    aria-label="confirm"
                    className={classNames(
                      "btn-confirm nst-btn",
                      "btn my-1 w-full max-w-40 rounded-3xl mx-1",
                      btnConfirmClass,
                    )}
                    onClick={confirm}
                    disabled={disableBtn}
                  >
                    {btnConfirm}
                  </button>
                )}
              </div>
            )
          }

        </div>
      </div>

      <div
        className="modal-backdrop"
        onClick={() => !noBackdropClose && close()}
      ></div>
    </dialog>
  )
}