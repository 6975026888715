/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLoadingContext } from "../../context/loading";
import "./style.css";
import classNames from "classnames";
import CoverPage from "../../components/cover-page";
import BreadCrumb, { BreadCrumbItem } from "../../components/breadcrumb";
import { ROUTE } from "../../config";
import useGo from "../../helper/use-go";

export default function AboutUs() {
  const { setLoading } = useLoadingContext();
  const [events, setEvents] = useState<any[]>([]);
  const go = useGo();

  useEffect(() => {
    setLoading(false);
    handleSetEvent();
  }, []);

  const handleSetEvent = () => {
    setEvents([
      {
        id: 1,
        image: "/asset/images/mock/image.png",
        title: "The Art of Collaboration",
        detail:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet doloribus, tenetur voluptas unde aperiam, quasi quae explicabo impedit non et autem ipsam nisi! Ab, possimus! Soluta placeat quisquam minima.",
      },
      {
        id: 2,
        image: "/asset/images/mock/image.png",
        title: "Masters of Cultural Exploration",
        detail:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet doloribus, tenetur voluptas unde aperiam, quasi quae explicabo impedit non et autem ipsam nisi! Ab, possimus! Soluta placeat quisquam minima.",
      },
      {
        id: 3,
        image: "/asset/images/mock/image.png",
        title: "Championing Authentic Encounters",
        detail:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet doloribus, tenetur voluptas unde aperiam, quasi quae explicabo impedit non et autem ipsam nisi! Ab, possimus! Soluta placeat quisquam minima.",
      },
    ]);
  };

  return (
    <div
      className={classNames(
        "about-container"
        // "m-h-screen",
        // "pt-[70px] lg:pt-[74.5px]"
      )}
    >
      <BreadCrumb
        className={classNames(
          "bg-white text-gray-800 mt-[70px] z-10",
          "lg:mt-[74.5px] w-full absolute"
        )}
      >
        <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
        <BreadCrumbItem title="About Us" isActive />
      </BreadCrumb>

      {/* Header */}
      <div className="header-container">
        <CoverPage
          // image="/asset/images/mock/cover-about.png"
          title={"About Us"}
          heightDetail={"lg:h-1/4"}
          isHideSearch
          isMiniScreen
          className="pt-12"
        />
      </div>

      {/* Content */}
      <div className="content-container mt-10 mb-16">
        {/* Discover */}
        <div className={classNames("discover py-5 m-auto", "w-10/12 lg:w-2/3")}>
          <p className="text-[1.2rem] lg:text-[1.4rem] font-semibold">
            Discover Northern Smile Travel: Your Premier Parner in Tailored
            Journeys
          </p>
          <p className="text-[0.9rem] pt-4">
            At Northern Smile Travel, we're more than a travel agency; we're
            your collaborative allies, shaping extraordinary journeys tailored
            to create unforgettable memories. Our dedication to perfection and
            personalized service makes us a trailblazing B2B travel services
            provider rooted in curating remarkable travel narratives.
          </p>
        </div>

        {/* Who we are */}
        <div className="who-we-are pt-5 pb-10 w-2/3 m-auto">
          <p className="text-[1.2rem] lg:text-[1.4rem] font-semibold">Who We Are</p>
          <p className="text-[0.9rem] pt-4">
            At Northern Smile Travel, we're more than a travel agency; we're
            your collaborative allies, shaping extraordinary journeys tailored
            to create unforgettable memories. Our dedication to perfection and
            personalized service makes us a trailblazing B2B travel services
            provider rooted in curating remarkable travel narratives.
          </p>
        </div>
      </div>

      <div className="content-container mt-10 pt-16 pb-10 lg:pt-0 bg-[#3E545B]">
        {/* Events */}
        <div
          className={classNames(
            "event m-auto bg-white",
            "p-4 pb-0 w-10/12",
            "lg:px-10 lg:pt-10 lg:w-2/3"
          )}
        >
          {events &&
            events?.map((item: any, index: number) => {
              return (
                <div
                  className="lg:grid lg:grid-cols-3 pb-10 m-auto"
                  key={`about-event-${index}`}
                >
                  <div className="col-span-1 w-fit m-auto">
                    <img
                      src={item?.image}
                      alt={`about-event-${index}`}
                      className="h-auto max-h-52"
                    />
                  </div>
                  <div className="col-span-2 w-full text-left content-center pl-1 pt-2 lg:pl-5">
                    <p className="text-[1.25rem] font-semibold mb-1">
                      {item?.title}
                    </p>
                    <p className="text-[0.8rem]">{item?.detail}</p>
                  </div>
                </div>
              );
            })}
        </div>

        {/* Why choose us */}
        <div
          className={classNames(
            "why-choose-us m-auto bg-white text-left rounded-b-md",
            "p-4 pb-10 w-10/12",
            "lg:w-2/3 lg:px-10"
          )}
        >
          <p className="text-[1.25rem] font-semibold mb-4">Why Choose Us</p>
          <p className="text-[0.8rem] mb-2 lg:mb-0">
            Local Wisdom: Our deep regional insights uncover hidden gems,
            shaping unparalleled itiberaries.
          </p>
          <p className="text-[0.8rem] mb-2 lg:mb-0">
            Tailored Excellence: Every journey is unique. We meticulously craft
            experiences aligning with individual preferences.
          </p>
          <p className="text-[0.8rem] mb-2 lg:mb-0">
            Sustainability: Advocates of responsible tourism, we leave a
            positive impact on destinations and commmunities.
          </p>
          <p className="text-[0.8rem] mb-2 lg:mb-0">
            unleashingInnovation: Our creative spirit crafts journeys brimming
            with surprises, turning the ordinary into the extraordinary.
          </p>
        </div>
      </div>
    </div>
  );
}
