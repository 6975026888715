export const scrollToTop = (behavior: ScrollBehavior = "smooth") => {
  window.scrollTo({
    top: 0,
    behavior,
  });
};

export const scrollToElement = (id: string, behavior: ScrollBehavior = "smooth") => {
  if (id) {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior });
  }
}