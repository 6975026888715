import classNames from "classnames";
import { Tour } from "../../../../../interface/model/tour";

interface TourDatesAndPricesProps {
  className?: string;
  data: Tour;
}

export default function TourDatesAndPrices({
  className,
  data,
}: TourDatesAndPricesProps) {
  return (
    <div
      className={classNames(
        "mt-10",
        className
      )}
    >
      Tour Dates And Prices
    </div>
  )
}