import "./style.css";
import classNames from "classnames";
import {
  FacebookRounded,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { ROUTE } from "../../config/route";
import useGo from "../../helper/use-go";
import { useState } from "react";
import Modal from "../../components/modal";
import ModalPrivacyPolicy from "../../components/modal-privacy-policy";
import ModalTermsAndConditions from "../../components/modal-terms-and-conditions";
import { SubscribeService } from "../../api/subscribe";
import { SubscribeForm } from "../../interface/model/subscribe";
import { CheckCircleOutlineOutlined, WarningAmberOutlined, ErrorOutlineRounded } from '@mui/icons-material';
import { useLoadingContext } from "../../context/loading";
import { RESPONSE_STATUS } from "../../config";

export default function Footer() {
  const go = useGo();
  const { setLoading } = useLoadingContext();

  const [form, setForm] = useState<SubscribeForm>({
    email: ''
  });
  const [modalSubscribeData, setModalSubscribeData] = useState<any>({});

  const [openModalConfirmSubscribe, setOpenModalConfirmSubscribe] = useState<boolean>(false);
  const [openModalPrivacyPolicy, setOpenModalPrivacyPolicy] = useState<boolean>(false);
  const [openModalTermsAndConditions, setOpenModalTermsAndConditions] = useState<boolean>(false);

  const mainMenu = [
    {
      title: "Destinations",
      link: ROUTE.DESTINATION
    },
    {
      title: "Services",
      link: ROUTE.SERVICE
    },
    {
      title: "About Us",
      link: ROUTE.ABOUT_US
    },
    {
      title: "Contact Us",
      link: ROUTE.CONTACT_US
    },
  ];

  const destinations = [
    {
      id: 1,
      title: "Thailand",
      link: `${ROUTE.DESTINATION}/1`,
    },
    {
      id: 2,
      title: "Vietnam",
      link: `${ROUTE.DESTINATION}/2`,
    },
    {
      id: 3,
      title: "Cambodia",
      link: `${ROUTE.DESTINATION}/3`,
    },
    {
      id: 4,
      title: "Loas",
      link: `${ROUTE.DESTINATION}/4`,
    },
    {
      id: 5,
      title: "Myanmar",
      link: `${ROUTE.DESTINATION}/5`,
    },
    {
      id: 6,
      title: "Indochina",
      link: `${ROUTE.DESTINATION}/6`,
    },
  ];

  const services = [
    {
      id: 1,
      title: "Tailor Made",
      link: `${ROUTE.SERVICE}/1`,
    },
    {
      id: 2,
      title: "Day Trips",
      link: `${ROUTE.SERVICE}/2`,
    },
    {
      id: 3,
      title: "Group",
      link: `${ROUTE.SERVICE}/3`,
    },
    {
      id: 4,
      title: "Event",
      link: `${ROUTE.SERVICE}/4`,
    }
  ];

  const onChangeEmailSubscribe = (event: any) => {
    const email = event?.target.value;
    setForm({ email });
  };

  const handleSubscribe = async () => {
    setLoading(true);

    const service = SubscribeService();
    const res = await service.subscribe(form);
    if (res && res.status !== RESPONSE_STATUS.ERROR) {
      setModalSubscribeData({
        status: res.status,
        message: res.message,
      });
      setOpenModalConfirmSubscribe(true);
      setForm({
        email: ''
      });
      setLoading(false);
    } else {
      setModalSubscribeData({
        status: res.status,
        message: res.error.message.email[0],
      });
      setOpenModalConfirmSubscribe(true);
      setForm({
        email: ''
      });
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Join NST */}
      <div
        className={classNames(
          "wrapper-join-nst",
          "min-h-32 p-10 mt-44 lg:mt-0",
        )}
      >
        <p className="text-center text-xl lg:text-3xl font-semibold text-gray-700">
          Join the Northern Smile Travel
        </p>
      </div>

      <div className="bg-gradient-to-t from-black from-5% to-[#354e57] to-70%">
        <footer
          className={classNames(
            "footer text-base-content p-10 shadow-inner",
            "text-gray-300 ",
          )}
        >
          <form className="min-w-80">
            <img
              className="h-auto max-w-40 m-auto"
              src="/asset/logo/nst-logo-white-no-bg.png"
              alt="nst-logo"
            />
            <fieldset className="form-control w-80">
              <label className="label">
                <span className="label-text text-gray-300 mb-3">
                  Your Gateway to Unforgettable Travel Experiences
                </span>
              </label>
              <div>
                <input
                  type="text"
                  placeholder="Your email..."
                  value={form.email}
                  className={classNames(
                    "input input-bordered join-item text-gray-300 border-white bg-transparent",
                    "hover:border-white focus-visible:border-white mr-2"
                  )}
                  onChange={onChangeEmailSubscribe}
                />

                <button
                  type="button"
                  className={classNames(
                    "btn text-gray-300 border-white join-item bg-transparent",
                    "hover:border-white hover:bg-gray-400 hover:text-black"
                  )}
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </div>
            </fieldset>
          </form>

          {/* Main menu */}
          <nav>
            <h6 className="footer-title">Menu</h6>
            {
              mainMenu && mainMenu?.map((item: any, index: number) => (
                <p
                  key={`main-menu-${index}`}
                  className="link link-hover"
                  onClick={() => go(item?.link)}
                >
                  {item?.title}
                </p>
              ))
            }
          </nav>

          {/* Destinations */}
          <nav>
            <h6 className="footer-title">Destinations</h6>
            {
              destinations && destinations?.map((item: any) => (
                <p
                  key={`destination-${item.id}`}
                  className="link link-hover"
                  onClick={() => go(item?.link)}
                >
                  {item?.title}
                </p>
              ))
            }
          </nav>

          {/* Services */}
          <nav>
            <h6 className="footer-title">Services</h6>
            {
              services && services?.map((item: any) => (
                <p
                  key={`service-${item.id}`}
                  className="link link-hover"
                  onClick={() => go(item?.link)}
                >
                  {item?.title}
                </p>
              ))
            }
          </nav>

          {/* Other link - Mobile */}
          <div
            className={classNames(
              "lg:hidden md:hidden",
              "footer-credit pb-10",
              "text-gray-300"
            )}
          >
            <div className="w-full">
              <hr />
            </div>

            <div className="text-left mb-3">
              {"Northern Smile Travel All Right Reserved.".toUpperCase()}
            </div>

            <div className="w-full lg:hidden">
              <div className="mb-5">
                <div className="text-sm flex gap-10">
                  <span className="cursor-pointer" onClick={() => setOpenModalPrivacyPolicy(true)}>
                    <u>Privacy Policy</u>
                  </span>
                  <span className="cursor-pointer" onClick={() => setOpenModalTermsAndConditions(true)}>
                    <u>Terms of Use</u>
                  </span>
                  <span className="cursor-pointer" onClick={() => go(ROUTE.CONTACT_US)}>
                    <u>Contact Us</u>
                  </span>
                </div>
              </div>
              <div className="text-center">
                <FacebookRounded className="cursor-pointer mx-2" />
                <Instagram className="cursor-pointer mx-2" />
                <Twitter className="cursor-pointer mx-2" />
                <YouTube className="cursor-pointer mx-2" />
              </div>
            </div>

          </div>
        </footer>

        {/* Other link - Desktop */}
        <div
          className={classNames(
            "hidden lg:block md:block",
            "footer-credit pb-10",
            " text-gray-300"
          )}
        >
          <div className="w-full p-10">
            <hr />
          </div>

          <div className="text-left pl-10 mb-3">
            {"Northern Smile Travel All Right Reserved.".toUpperCase()}
          </div>

          <div className="grid grid-cols-2">
            <div className="text-left pl-10">
              <div className="text-sm">
                <span className="cursor-pointer pr-5" onClick={() => setOpenModalPrivacyPolicy(true)}>
                  <u>Privacy Policy</u>
                </span>
                <span className="cursor-pointer px-5" onClick={() => setOpenModalTermsAndConditions(true)}>
                  <u>Terms of Use</u>
                </span>
                <span className="cursor-pointer pl-5" onClick={() => go(ROUTE.CONTACT_US)}>
                  <u>Contact Us</u>
                </span>
              </div>
            </div>
            <div className="text-right pr-10">
              <FacebookRounded className="cursor-pointer mx-2" />
              <Instagram className="cursor-pointer mx-2" />
              <Twitter className="cursor-pointer mx-2" />
              <YouTube className="cursor-pointer mx-2" />
            </div>
          </div>
        </div>
      </div>

      {/* Privacy policy modal */}
      <ModalPrivacyPolicy
        isOpen={openModalPrivacyPolicy}
        setIsOpen={(value: boolean) => setOpenModalPrivacyPolicy(value)}
      />

      {/* Terms and conditions modal */}
      <ModalTermsAndConditions
        isOpen={openModalTermsAndConditions}
        setIsOpen={(value: boolean) => setOpenModalTermsAndConditions(value)}
      />

      {/* Modal confirm subscribe */}
      <Modal
        title=""
        titlePosition="center"
        titleBorder={false}
        isOpen={openModalConfirmSubscribe}
        setIsOpen={(value: boolean) => setOpenModalConfirmSubscribe(value)}
        btnClose="Close"
        onClose={() => setOpenModalConfirmSubscribe(false)}
      >
        <div className="icon my-5">
          {
            modalSubscribeData?.status === "success" && (
              <CheckCircleOutlineOutlined style={{ fontSize: '60px' }} className="text-[#018181]" />
            )
          }
          {
            modalSubscribeData?.status === "warning" && (
              <WarningAmberOutlined style={{ fontSize: '60px' }} className="text-yellow-400" />
            )
          }
          {
            modalSubscribeData?.status === "error" && (
              <ErrorOutlineRounded style={{ fontSize: '60px' }} className="text-red-400" />
            )
          }
        </div>
        <div className="text-xl font-semibold mb-10">
          <span dangerouslySetInnerHTML={{__html: modalSubscribeData?.message?.replaceAll('\n', '<br/>')}}></span>
        </div>
      </Modal>
    </div>
  );
}
