import { ContactForm, ContactFormValid } from "../model/contact";
import { Tour } from "../model/tour";

export interface GetAQuoteForm extends ContactForm {
  tour?: Tour;
}

export const mockGetAQuoteForm: GetAQuoteForm = {
  travel_date: "",
  firstname: "",
  lastname: "",
  phone_number: "",
  email: "",
  accept_policy: false,
  message: "",
}

export interface GetAQuoteFormValid extends ContactFormValid {
  tour_valid?: string;
}

export const mockGetAQuoteFormValid: GetAQuoteFormValid = {
  travel_date_valid: "",
  firstname_valid: "",
  lastname_valid: "",
  phone_number_valid: "",
  email_valid: "",
  accept_policy_valid: "",
  message_valid: "",
  tour_valid: "",
}
