import LoadingContextProvider from "./loading";

export default function ContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <LoadingContextProvider>
      {children}
    </LoadingContextProvider>
  );
}
