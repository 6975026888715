import classNames from "classnames";
import "./style.css";
import { COLOR, LOADING } from "../../config/constant";

interface LoadingProps {
  id?: string;
  type?: string;
  color?: string;
}

export default function Loading({
  id,
  type = LOADING.SPINNER,
  color = COLOR.PRIMARY,
}: LoadingProps) {
  return (
    <div
      id={id}
      className="w-full overlay"
    >
      <span
        className={classNames(
          "loading loading-custom loading-lg z-50 blur-sm text-white",
          type && `loading-${type}`,
          color && `text-${color}`
        )}
      ></span>
    </div>
  );
}
