import apiService from "./api-service";
import { ContactForm } from "../interface/model/contact";

export const ContactService = () => {
  const api = apiService();
  const path = "/contact";

  return {
    contact: (payload: ContactForm): Promise<any> => {
      return api.post(`${path}`, payload);
    }
  }
}